import "./playerButtons.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";

import { Config } from "../../../config";
import { IPlayer, PlayerStreamType } from "../../../tools/player";
import { AudioButtonPlayer } from "./buttons/audioButtonPlayer";
import { ButtonPlayer } from "./buttons/buttonPlayer";
import { FwdButtonPlayer } from "./buttons/fwdButtonPlayer";
import { GoToLiveButtonPlayer } from "./buttons/goToLiveButtonPlayer";
import { InfoButtonPlayer } from "./buttons/infoButtonPlayer";
import { NextButtonPlayer } from "./buttons/nextButtonPlayer";
import { PauseButtonPlayer } from "./buttons/pauseButtonPlayer";
import { PreviousButtonPlayer } from "./buttons/previousButtonPlayer";
import { RestartButtonPlayer } from "./buttons/restartButtonPlayer";
import { RwdButtonPlayer } from "./buttons/rwdButtonPlayer";

enum Button {
  RESTART = "restart",
  REWIND = "rewind",
  REWIND_DISABLED = "rewindDisabled",
  PLAYPAUSE = "playpause",
  FORWARD = "forward",
  FORWARD_DISABLED = "forwardDisabled",
  AUDIO = "audio",
  INFO = "info",
  NEXT = "next",
  PREVIOUS = "previous",
  GO_TO_LIVE = "goToLive",
}

const buttonConfiguration: { [key: string]: Button[] } = {};
buttonConfiguration[PlayerStreamType.VIDEO] = [
  Button.RESTART,
  Button.PREVIOUS,
  Button.REWIND,
  Button.PLAYPAUSE,
  Button.FORWARD,
  Button.NEXT,
  Button.AUDIO,
  Button.INFO,
];
buttonConfiguration[PlayerStreamType.VIDEO_LIVE] = [
  Button.RESTART,
  Button.REWIND,
  Button.PLAYPAUSE,
  Button.FORWARD_DISABLED,
  Button.AUDIO,
  Button.INFO,
];
buttonConfiguration[PlayerStreamType.VIDEO_SHIFT] = [
  Button.RESTART,
  Button.GO_TO_LIVE,
  Button.REWIND,
  Button.PLAYPAUSE,
  Button.FORWARD,
  Button.AUDIO,
  Button.INFO,
];
buttonConfiguration[PlayerStreamType.AUDIO] = [
  Button.RESTART,
  Button.PREVIOUS,
  Button.REWIND,
  Button.PLAYPAUSE,
  Button.FORWARD,
  Button.NEXT,
  Button.INFO,
];
buttonConfiguration[PlayerStreamType.AUDIO_LIVE] = [
  Button.REWIND,
  Button.PLAYPAUSE,
  Button.FORWARD_DISABLED,
  Button.INFO,
];
buttonConfiguration[PlayerStreamType.AUDIO_SHIFT] = [
  Button.GO_TO_LIVE,
  Button.REWIND,
  Button.PLAYPAUSE,
  Button.FORWARD,
  Button.INFO,
];
buttonConfiguration[PlayerStreamType.VIDEO_LIVE_AD_REPLACEMENT] = [
  Button.REWIND,
  Button.PLAYPAUSE,
  Button.FORWARD_DISABLED,
  Button.AUDIO,
  Button.INFO,
];
buttonConfiguration[PlayerStreamType.VIDEO_SHIFT_AD_REPLACEMENT] = [
  Button.GO_TO_LIVE,
  Button.REWIND,
  Button.PLAYPAUSE,
  Button.FORWARD,
  Button.AUDIO,
  Button.INFO,
];
buttonConfiguration[PlayerStreamType.VIDEO_FAST_TV] = [Button.AUDIO, Button.INFO];
buttonConfiguration[PlayerStreamType.VIDEO_FAST_TV_PAUSED] = [Button.PLAYPAUSE, Button.AUDIO, Button.INFO];

export class PlayerButtons extends AcceptsMouseFocusView {
  list: DS.IListComponent<Button, ButtonPlayer>;
  modelSource$ = new DS.ModelSource<Button>([]);
  player: IPlayer;
  checkTime = -1;

  private _focusedButtonId$: DS.Listenable<string | undefined>;

  constructor(player: IPlayer, focusedButtonId$: DS.Listenable<string | undefined>) {
    super("PlayerButtons");
    this.player = player;
    this._focusedButtonId$ = focusedButtonId$;

    player.type$.didChange(
      type => {
        this._changeType(type);
      },
      this,
      true
    );

    this.delegate = this.list = DS.createListComponent(
      {
        id: "",
        className: "playerButtons",
        modelSource$: this.modelSource$,
        viewFactory: button => {
          switch (button) {
            case Button.RESTART:
              return new RestartButtonPlayer(player);
            case Button.REWIND:
              return new RwdButtonPlayer(player);
            case Button.REWIND_DISABLED:
              return new RwdButtonPlayer(player, true);
            case Button.PLAYPAUSE:
              return new PauseButtonPlayer(player);
            case Button.FORWARD:
              return new FwdButtonPlayer(player);
            case Button.FORWARD_DISABLED:
              return new FwdButtonPlayer(player, true);
            case Button.AUDIO:
              return new AudioButtonPlayer(player);
            case Button.INFO:
              return new InfoButtonPlayer(player);
            case Button.NEXT:
              return new NextButtonPlayer(player);
            case Button.PREVIOUS:
              return new PreviousButtonPlayer(player);
            case Button.GO_TO_LIVE:
              return new GoToLiveButtonPlayer(player);
          }
        },
        scrollingMode: { type: DS.ScrollingType.none, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        noTransform: true,
        onSelect: button => {
          this._focusedButtonId$.value = button;
          return this.list.viewFromId(button)?.onSelect() ?? false;
        },
      },
      list => {
        void list.setFocusOnId(this._focusedButtonId$.value ?? Button.PLAYPAUSE, { focus: false });
      }
    );
  }

  private _changeType(type: PlayerStreamType | undefined) {
    if (this.list !== undefined) {
      const focusedID: string | undefined = this.list.focusedId$.value;
      void this.list.setFocusOnId(undefined);
      if (focusedID !== undefined && this.list.focused$.value) {
        const release = this.list.focusedId$.didChange(() => {
          release();
          void this.list.setFocusOnId(focusedID);
        }, this);
      }
    }
    if (type === PlayerStreamType.AUDIO_LIVE && this.player.duration$.value < 90) {
      this.modelSource$.value = [Button.REWIND_DISABLED, Button.PLAYPAUSE, Button.FORWARD_DISABLED, Button.INFO];
      this.checkTime = window.setTimeout(() => {
        this._changeType(this.player.type$.value);
      }, 5000);
    } else {
      this.modelSource$.value = buttonConfiguration[type ?? PlayerStreamType.VIDEO];
    }
  }
}
