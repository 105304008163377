import { Didomi } from "~/typings/didomi";

import { CallFlow } from "../../callflow";
import { Config } from "../../datas/consts";
import { navigationStack } from "../../main";
import { DidomiPopupPageView } from "../../pages/consent/didomi/didomiPopupPage";

/**
 * Handle Didomi initialization with a timeout
 */
export const initializeDidomi = (timeout: number) => {
  return new Promise<void>((resolve, reject) => {
    const timeoutLoadingDidomi = window.setTimeout(() => {
      Log.app.log("didomi loading timeout");
      window.didomiOnReady = undefined;
      resolve();
    }, timeout);

    window.didomiOnReady = window.didomiOnReady ?? [];
    window.didomiOnReady.push((didomi: Didomi) => {
      Log.app.log("didomiOnReady");
      clearTimeout(timeoutLoadingDidomi);
      resolve();
    });

    void importDidomi();
  });
};

const importDidomi = async () => {
  // Setting Didomi Config
  window.didomiConfig = {
    mode: "tv", // Enable TV mode
    notice: {
      daysBeforeShowingAgain: 180, // Number of days. Default is 0, currently set to 6 months
      enable: true,
    },
  };

  // Setting GDPR Config
  window.gdprAppliesGlobally = true;

  // Importing didomi SDK script, and initialize window.importDidomiSdk
  await require("./didomiImportScript.js");

  // Initializing Didomi
  if (window.importDidomiSdk !== undefined) {
    const config = Config();

    window.importDidomiSdk(config.DIDOMI.APIKey, config.DIDOMI.NoticeId);
  }
};

/**
 * check if All partners are enabled
 */
export const areAllPartnersAccepted = () => {
  // Didomi needs to be initialised in main.ts
  if (window.Didomi !== undefined) {
    const result = window.Didomi.getCurrentUserStatus();
    return Object.values(result.vendors).every(vendor => vendor.enabled);
  }

  return false;
};

/**
 * Delete Didomi consent informations (cookies + local storage)
 */
export const resetDidomiLocalInformations = () => {
  const topLevelDomain = location.hostname.split(".").slice(-2).join(".");
  ["didomi_token", "euconsent-v2"].forEach(cookie => {
    document.cookie = `${cookie}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;Domain=.${topLevelDomain}`;
    localStorage.removeItem(cookie);
  });
};

export const showDidomiPage = () => {
  navigationStack.destroyStack();

  if (window.Didomi) {
    navigationStack.pushPage(
      new DidomiPopupPageView(window.Didomi, {
        context: "startup",
        onClose: () => CallFlow.initHome(),
      })
    );
  }
};
