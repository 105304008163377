import { DS } from "~/libs";
import { deeplinkManager } from "~/tools/deeplinkManager";

import { Config } from "../config";
import { IDelegate } from "../libs/exports";
import { navigationStack } from "../main";
import { GenericPage } from "../pages/generic/genericPage";
import { PlayerPage } from "../pages/player/playerPage";
import { RootMenuView } from "./rootMenuView";

enum TopLevelID {
  menu = "menu",
  content = "content",
}

export class RootMenuPage extends DS.View implements IDelegate {
  private _list;
  private static _inputModeBeforeKeyBackWasPointer = false;

  constructor(id: string, className: string, contentGenerator: () => DS.IView) {
    super(id, className);

    this.delegate = this._list = DS.createListComponent(
      {
        id: `${id}/list`,
        className: "full",
        modelSource$: new DS.ModelSource([TopLevelID.menu, TopLevelID.content]),
        viewFactory: item => {
          switch (item) {
            case TopLevelID.menu:
              return RootMenuView.instance;

            default:
              return contentGenerator();
          }
        },
        // noTransform: true,
        scrollingMode: { type: DS.ScrollingType.none, horizontal: true },
        mouseSupport: Config.mouseSupport && {
          focusRange: "visible",
        },
      },
      list => {
        // always provide focus to the content
        void list.setFocusOnId(TopLevelID.content);
      }
    );

    DS.inputMode$.didChange(
      inputMode => {
        if (inputMode === DS.InputMode.pointer) {
          RootMenuPage._inputModeBeforeKeyBackWasPointer = true;
        }
      },
      this,
      true
    );
  }

  public static launchHomePage() {
    navigationStack.destroyStack();
    RootMenuView.triggerHomeItemSelection();
  }

  public static navigateBackToHome() {
    const isSinglePageInStack = navigationStack.pages$.value.length === 1;
    const isContentGenericPage =
      navigationStack.topPage instanceof GenericPage && !["/home", "/kids"].includes(navigationStack.topPage.path);
    const isPlayerPage = navigationStack.topPage instanceof PlayerPage;

    if (isSinglePageInStack && (isContentGenericPage || isPlayerPage)) {
      this.launchHomePage();
      return true;
    }

    return false;
  }

  onNav = (key: DS.Keys) => {
    if (key === DS.Keys.back) {
      if (deeplinkManager.hasDeeplinkContext === true && RootMenuPage.navigateBackToHome()) {
        return true;
      }
      // Specs: if we are on content (this.rootList?.focusedId$.value === TopLevelID.content)
      // And the user press back, the app should open the menu
      // (navigationStack.pages$.value.length === 1) is here to check that we are on top-level page
      if (
        this._list?.focusedId$.value === TopLevelID.content &&
        navigationStack.pages$.value.length === 1 &&
        RootMenuPage._inputModeBeforeKeyBackWasPointer === false
      ) {
        void this._list.setFocusOnId(TopLevelID.menu);
        return true;
      }

      // If we have been on the menu and we press back the menu must close
      if (this._list.focusedId$.value === TopLevelID.menu && navigationStack.pages$.value.length > 1) {
        this._list.onUnfocused && this._list.onUnfocused();
      }
    }

    RootMenuPage._inputModeBeforeKeyBackWasPointer = false;
    return false;
  };

  onShown() {
    if (this._list.focusedId$.value === TopLevelID.menu) {
      void this._list.setFocusOnId(TopLevelID.content);
    }
  }
}
