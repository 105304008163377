import "./rootMenuView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";
import { DS } from "~/libs";
import { debounce } from "~/libs/exports";
import { GenericIncentivePageFull, IncentiveType } from "~/pages/incentive/incentivePage";

import { StickyPlayer } from "../components/player/sticky/stickyPlayer";
import { Config } from "../config";
import { navigationStack } from "../main";
import { GenericPage } from "../pages/generic/genericPage";
import { openKids } from "../pages/onBoarding/onBoardingPage";
import { ExitKidsPopupPage } from "../pages/parentalControl/parentalPopupPage";
import { PlayerPage } from "../pages/player/playerPage";
import { ProfilPage } from "../pages/profil/profilPage";
import { SettingsPage } from "../pages/settings/settingsPage";
import { DevicePreferenceHelper } from "../tools/devicePreferencesManager";
import { playerAudio, PlayerState, PlayerStreamType } from "../tools/player";
import { SettingsHelper } from "../tools/settingsHelper";
import { TrackingHelper } from "../tools/trackingHelper";
import { SnowplowDataLayerEventName } from "../typings/snowplow";
import { MenuEntry } from "../utils/rtbf/models";

type RootMenuItem = {
  id: string;
  slug: string;
  iconKey: string;
  label: string;
  logo: string;
  path: string;
};

class RootMenuItemView extends AcceptsMouseFocusView {
  protected _icon: HTMLDivElement;
  protected _iconFocused: HTMLDivElement;
  protected _iconSelected: HTMLDivElement;
  protected _title: HTMLDivElement;

  constructor(item: RootMenuItem) {
    super(`menuItem/${item.slug}`, `rootMenuItem ${item.slug}`);

    this._icon = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: "icon",
      className: "rootMenuItemIcon",
      style: {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    this._iconFocused = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: "icon",
      className: "rootMenuItemIconFocused",
      style: {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    this._iconSelected = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: "icon",
      className: "rootMenuItemIconSelected",
      style: {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });

    if (item.iconKey.includes("http")) {
      this._icon.style.backgroundImage = `url(${item.iconKey})`;
      this._iconFocused.style.backgroundImage = `url(${item.iconKey})`;
      this._iconSelected.style.backgroundImage = `url(${item.iconKey})`;
    } else {
      try {
        const img = require(`@assets/images/icons/${item.iconKey}.png`);
        const imgFocused = require(`@assets/images/icons/${item.iconKey}-focused.png`);
        const imgSelected = require(`@assets/images/icons/${item.iconKey}-selected.png`);

        this._icon.style.backgroundImage = `url(${img})`;
        this._iconFocused.style.backgroundImage = `url(${imgFocused})`;
        this._iconSelected.style.backgroundImage = `url(${imgSelected})`;
      } catch (e: unknown) {
        Log.app.error("Impossible de configurer l'image: ", item.iconKey);
      }
    }

    this._title = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      id: "title",
      className: "rootMenuItemTitle",
    });
    Config.locale$.didChange(
      () => {
        this._title.innerText = item.label;
      },
      this,
      true
    );
  }
}

class RootMenuProfileView extends RootMenuItemView {
  constructor(item: RootMenuItem) {
    super(item);

    APIGigyaOIDC.userInfo$.didChange(
      value => {
        if (value?.picture !== undefined) {
          this._icon.style.backgroundImage = `url(${value.picture})`;
          this._iconFocused.style.backgroundImage = `url(${value.picture})`;
          this._iconSelected.style.backgroundImage = `url(${value.picture})`;
        }
        if (value?.given_name !== undefined) {
          this._title.innerText = value.given_name;
        }
      },
      this,
      true
    );
  }
}

type RootMenuViews = RootMenuItemView | RootMenuProfileView | StickyPlayer;

class RootMenuViewImplementation extends AcceptsMouseFocusView {
  private _list: DS.IListComponent<RootMenuItem, RootMenuViews>;
  private _modelSource$: DS.ModelSource<RootMenuItem>;
  private _refreshView = false;
  private _hasPlayer = false;
  private _items: MenuEntry[];
  private _kidsItems: MenuEntry[];
  private _currentMenuItem: RootMenuItem;
  private _homeMenuItem: RootMenuItem;
  // Prevent empty menu when viewsUpdateCompleted is called multiple times on very short time
  // The bug is related to the player / sticky player destroy calls
  private _preventBlackMenu = debounce((list: DS.IListComponent<RootMenuItem, RootMenuViews>) => {
    if (list.visibleRange$.value.first === undefined) {
      void list.refresh();
    }
  }, 200);

  constructor(items: MenuEntry[]) {
    super("rootMenu", "rootMenu");
    // the root menu doesn't go away. So cache it
    this.persistency = DS.IViewPersistency.static;

    this._items = items;
    this._kidsItems = SettingsHelper.instance.data?.["menu-kids"].primary ?? [];

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "logo",
    });

    let array: RootMenuItem[];
    if (DevicePreferenceHelper.kidsEnable$.value) {
      array = this._kidsArray();
      this.rootElement.classList.add("kidsRootMenu");
      this._currentMenuItem = this._kidsItems[0];
      this._homeMenuItem = this._kidsItems[0];
    } else {
      array = this._array();
      this._currentMenuItem = items[0];
      this._homeMenuItem = items[0];
    }

    this.delegate = this._list = DS.createListComponent(
      {
        id: "rootMenu/list",
        onModelSourceChangedRefreshParams: { keepSelection: false },
        className: "rootMenuList",
        modelSource$: (this._modelSource$ = new DS.ModelSource(array)),
        viewFactory: item => {
          if (item.id === "player") {
            return new StickyPlayer(playerAudio);
          }
          if (item.id === "user") return new RootMenuProfileView(item);
          return new RootMenuItemView(item);
        },
        onSelect: this.onMenuSelect,
        scrollingMode: { type: DS.ScrollingType.none, horizontal: false },
        mouseSupport: Config.mouseSupport && {
          focusRange: "visible",
        },
        noTransform: true,
        defaultFocusId: this._currentMenuItem?.id,
      },
      list => {
        list.viewFromId(this._currentMenuItem.id)?.rootElement.classList.add("current");
        list.viewsUpdateCompletedTrigger.didSignal(_ => {
          this._preventBlackMenu(list);
        }, this);
      }
    );

    DevicePreferenceHelper.kidsEnable$.didChange(async value => {
      if (value) {
        this.rootElement.classList.add("kidsRootMenu");
        this.refreshMenu();
        this._homeMenuItem = this._kidsItems[0];
        this._currentMenuItem = this._kidsItems[0];
        if (playerAudio.state$.value !== PlayerState.IDLE) {
          void playerAudio.destroy();
        }
      } else if (!value) {
        this.rootElement.classList.remove("kidsRootMenu");
        this._homeMenuItem = items[0];
        this._currentMenuItem = items[0];
      }
      this._list.viewFromId(this._currentMenuItem.id)?.rootElement.classList.add("current");
      await this._list.setFocusOnId(this._currentMenuItem?.id, { focus: false });
    }, this);

    playerAudio.type$.didChange(value => {
      if (
        value === PlayerStreamType.AUDIO ||
        value === PlayerStreamType.AUDIO_LIVE ||
        value === PlayerStreamType.AUDIO_SHIFT
      ) {
        this._displayPlayer();
      } else {
        this._releasePlayer();
      }
    }, this);
  }

  private _array(): RootMenuItem[] {
    let avatar = "user-anonymous";
    if (APIGigyaOIDC.userInfo$.value?.picture !== undefined) {
      if (APIGigyaOIDC.userInfo$.value?.picture.includes("http")) avatar = APIGigyaOIDC.userInfo$.value?.picture;
      else avatar = "user-connected";
    }
    const base = [
      {
        id: "user",
        iconKey: avatar,
        logo: "",
        slug: "user",
        label: APIGigyaOIDC.userInfo$.value?.given_name ?? t("menu.login"),
        path: "/user",
      },
      ...this._items,
      {
        id: "parameters",
        iconKey: "param",
        logo: "",
        slug: "param",
        label: t("menu.settings"),
        path: "/param",
      },
    ];
    if (this._hasPlayer) {
      base.splice(-2, 0, {
        id: "player",
        iconKey: "param",
        logo: "",
        slug: "player",
        label: "Player",
        path: "/player",
      });
    }
    return base;
  }

  private _kidsArray(): RootMenuItem[] {
    const base = [
      ...this._kidsItems.map(item => {
        item.slug.includes("explorer") ? (item.iconKey = "explorer") : "home";
        return item;
      }),
      {
        id: "closeKids",
        iconKey: "close",
        logo: "",
        slug: "close",
        label: t("menu.close"),
        path: "/home",
      },
    ];

    return base;
  }

  onFocused = () => {
    void this._list.setFocusOnId(this._currentMenuItem?.id);
  };

  refreshHome = () => {
    this.refreshMenu();
    this._refreshView = true;
    this.triggerHomeItemSelection();
  };

  triggerHomeItemSelection = () => {
    this.onMenuSelect(this._homeMenuItem);
  };

  setHomeItemSelected = () => {
    const currentView = this._list.viewFromId(this._currentMenuItem.id);
    if (currentView) {
      currentView.rootElement.classList.remove("current");
    }

    this._currentMenuItem = this._homeMenuItem;

    const homeView = this._list.viewFromId(this._currentMenuItem.id);
    if (homeView) {
      homeView.rootElement.classList.add("current");
    }
  };

  onMenuSelect = (model: RootMenuItem) => {
    const needToLoadHome =
      model.path === "/" &&
      navigationStack.topPage instanceof GenericPage &&
      !["/home", "/kids"].includes(navigationStack.topPage.path);

    // prevent reopening the same page
    if (
      this._currentMenuItem.id === model.id &&
      this._currentMenuItem.id !== "player" &&
      this._currentMenuItem.id !== "user" &&
      !this._refreshView &&
      !DevicePreferenceHelper.kidsEnable$.value &&
      navigationStack.pages$.value.length === 1 &&
      !needToLoadHome
    )
      return false;

    const currentPagePath = TrackingHelper.getCurrentPageUrl();
    const referrerPagePath = TrackingHelper.getPageReferrerUrl();

    this._refreshView = false;

    const oldV = this._list.viewFromId(this._currentMenuItem?.id);
    const newV = this._list.viewFromId(model.id);

    oldV?.rootElement.classList.remove("current");
    newV?.rootElement.classList.add("current");

    this._currentMenuItem = model;

    let destroyStackEnable = true;

    if (["player", "closeKids"].indexOf(model.id) !== -1) {
      destroyStackEnable = false;
    } else if (model.id === "user" && APIGigyaOIDC.isConnected() === false) {
      destroyStackEnable = false;
    }

    if (destroyStackEnable) navigationStack.destroyStack();

    TrackingHelper.track({
      event_name: "menu_item_selected",
      gtag: {
        menu_entry: model.label,
      },
    });

    const closeKidsCallback = () => {
      DevicePreferenceHelper.switchKids(!DevicePreferenceHelper.kidsEnable$.value);
      this.refreshHome();
    };

    switch (model.id) {
      case "user":
        {
          if (APIGigyaOIDC.userInfo$.value !== undefined) navigationStack.pushPage(new ProfilPage());
          else navigationStack.pushPage(new GenericIncentivePageFull(IncentiveType.profileNotConnected));
        }
        break;
      case "parameters":
        navigationStack.pushPage(new SettingsPage());
        break;
      case "player":
        void PlayerPage.playAsset(playerAudio.asset$.value?.resource.assetId ?? "", "AUDIO", "MEDIA");
        break;
      case "closeKids":
        if (DevicePreferenceHelper.isUserKidsSecured() && APIGigyaOIDC.isConnected()) {
          navigationStack.pushPage(new ExitKidsPopupPage(closeKidsCallback));
        } else {
          closeKidsCallback();
        }
        break;
      default:
        if (model.path === "/mes-contenus" || model.path === "/mon-auvio" /* && user not log in */) {
          navigationStack.pushPage(new GenericPage(model.path));
          /* Disabled incentive to login if necessary because it's difficult to setup on other platforms
          if (this.user$.value !== undefined) navigationStack.pushPage(new GenericPage(model.path));
          else navigationStack.pushPage(new GenericIncentivePage(IncentiveType.myContent));
          */
        } else {
          if (model.path === "/kids") {
            openKids();
          } else {
            navigationStack.pushPage(
              new GenericPage(
                model.path === "/" ? (!DevicePreferenceHelper.kidsEnable$.value ? "/home" : "/kids") : model.path
              )
            );
          }
        }
        break;
    }

    void TrackingHelper.snowplowEvent(SnowplowDataLayerEventName.navigation_click, {
      menu_title: model.label,
      page_location: currentPagePath,
      page_referrer: referrerPagePath,
    });

    return true;
  };

  get visible() {
    return this.rootElement.style.visibility === "visible";
  }

  set visible(visible: boolean) {
    this.rootElement.style.visibility = visible ? "visible" : "hidden";
  }

  private _releasePlayer() {
    this.rootElement.classList.remove("withSticky");
    this._hasPlayer = false;
    this.refreshMenu();
  }

  private _displayPlayer() {
    this.rootElement.classList.add("withSticky");
    this._hasPlayer = true;
    this.refreshMenu();
  }

  refreshMenu() {
    this._modelSource$.value = DevicePreferenceHelper.kidsEnable$.value ? this._kidsArray() : this._array();
  }
}

type RootMenuViewData = {
  menu: MenuEntry[];
  menuKids: MenuEntry[];
};

export class RootMenuView {
  private static _instance?: RootMenuViewImplementation;
  private static _data: RootMenuViewData;

  static set data(data: RootMenuViewData) {
    RootMenuView._data = data;
  }

  static get instance() {
    return (RootMenuView._instance = RootMenuView._instance ?? new RootMenuViewImplementation(RootMenuView._data.menu));
  }

  static refreshHome = async () => {
    RootMenuView.instance.refreshHome();
  };

  static refreshMenu = () => {
    RootMenuView.instance.refreshMenu();
  };

  static triggerHomeItemSelection = () => {
    RootMenuView.instance.triggerHomeItemSelection();
  };

  static setHomeItemSelected = () => {
    RootMenuView.instance.setHomeItemSelected();
  };
}
