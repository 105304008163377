import "./playerProgress.scss";

import { DS } from "~/libs";
import { PlayerCommon } from "~/player/playerCommon";

import { IPlayer, PlayerState } from "../../../tools/player";
import { ITextElement, TextHelper } from "../../../tools/textHelper";
import { convertSecondToMinutes, convertSecondToPlayerDuration, getFormatDate } from "../../../tools/time";

const SCRUBBER_SIZE = 22;

enum MoveDirection {
  left = "left",
  right = "right",
}

/**
 * Generic progress bar with no logic.
 */
class PlayerProgressBar extends DS.View {
  currentTimeDOM: HTMLElement;
  totalTimeDOM: HTMLElement;
  progressDOM: HTMLElement;
  progressBarContainer: HTMLElement;
  spriteCueTooltip: HTMLElement;
  player: IPlayer;

  protected _moveTime = Date.now();
  protected _moveCurrent = Date.now();

  private static _TIME_BETWEEN_MOVE = 500;
  private static _SPEED_PERCENTAGE_1 = 4;
  private static _SPEED_PERCENTAGE_2 = 7;
  private static _TIME_TRIGGER_1 = 200;
  private static _TIME_TRIGGER_2 = 900;

  constructor(player: IPlayer, classname = "") {
    super("", `playerProgressBar ${classname}`);
    this.player = player;

    this.currentTimeDOM = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "currentTime",
    });
    this.totalTimeDOM = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "totalTime",
    });
    this.progressBarContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "progressBar",
    });
    this.progressDOM = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.progressBarContainer,
      className: "progress",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.progressBarContainer,
      className: "scrubber",
    });
    this.spriteCueTooltip = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.progressBarContainer,
      className: "spriteCueTooltip",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.spriteCueTooltip,
      className: "spriteCue",
    });

    this._onKeyUp = this._onKeyUp.bind(this);
    window.addEventListener("keyup", this._onKeyUp);
  }

  onRelease() {
    super.onRelease();
    window.removeEventListener("keyup", this._onKeyUp);
  }

  private _onKeyUp(ev: KeyboardEvent) {
    const key = DS.mapKeyboardEvent(ev);
    switch (key) {
      case DS.Keys.left:
        this._move(MoveDirection.left, true);
        break;
      case DS.Keys.right:
        this._move(MoveDirection.right, true);
        break;
    }
  }

  onNav(key: DS.Keys): boolean {
    switch (key) {
      case DS.Keys.select:
        this.player.play();
        return true;
      case DS.Keys.down:
        if (this.player.state$.value === PlayerState.SEEKING) this.player.play();
        // Need to focus the controls so return false to not handle
        return false;
      case DS.Keys.left:
        this._move(MoveDirection.left);
        return true;
      case DS.Keys.right:
        this._move(MoveDirection.right);
        return true;
    }
    return false;
  }

  /**
   * Move in a specific direction. If we stay pressed, the speed will
   * grow up exponentially.
   * @param direction In which direction we will move
   * @param release If we stop the press
   * @returns void
   */
  protected _move(direction: MoveDirection, release = false): void {
    if (release || this.player.asset$.value?.isFastTV === true) {
      return;
    }
    let seekTime = direction === MoveDirection.left ? this.player.TIME_INCREMENT_RW : this.player.TIME_INCREMENT_FW;
    if (this._moveTime + PlayerProgressBar._TIME_BETWEEN_MOVE >= Date.now()) {
      const ellapsed = this._moveTime - this._moveCurrent;
      const seekableDuration =
        this.player.asset$.value && this.player.asset$.value?.isLive()
          ? this.player.seekable$.value.end - this.player.seekable$.value.start
          : this.player.duration$.value;
      if (ellapsed > PlayerProgressBar._TIME_TRIGGER_2) {
        seekTime = seekableDuration * PlayerProgressBar._SPEED_PERCENTAGE_2;
      } else if (ellapsed > PlayerProgressBar._TIME_TRIGGER_1) {
        seekTime = seekableDuration * PlayerProgressBar._SPEED_PERCENTAGE_1;
      }
      if (ellapsed > PlayerProgressBar._TIME_TRIGGER_1) {
        seekTime = (seekTime / 100) * (direction === MoveDirection.left ? -1 : 1);
      }
    } else {
      this._moveCurrent = Date.now();
    }
    this.player.pause();
    this.player.seek(seekTime, true);
    this._moveTime = Date.now();
  }
}

/**
 * The VOD Progress Bar.
 * Update the progress depending on the player current time and duration.
 */
export class PlayerVODProgressBar extends PlayerProgressBar {
  constructor(player: IPlayer) {
    super(player);
    // Callbacks
    player.currentTime$.didChange(this.update.bind(this), this, true);
    player.seekTime$.didChange(this.update.bind(this), this, true);
    player.duration$.didChange(
      value => {
        this.totalTimeDOM.innerText = convertSecondToPlayerDuration(value);
      },
      this,
      true
    );
  }

  update() {
    const time = this.player.seekTime$.value ?? this.player.currentTime$.value;
    this.currentTimeDOM.innerText = convertSecondToPlayerDuration(time);
    this.progressDOM.style.width = `${(time / this.player.duration$.value) * 100}%`;
  }
}

/**
 * Live progress bar.
 * Logic implemented to handle live case and time shifting case.
 */
export class PlayerVideoLiveProgressBar extends PlayerProgressBar {
  startDate = new Date();
  endDate = new Date();
  durationDOM: HTMLElement;
  private _timeTrack = -1;
  private _rejectsFocus = false;
  protected _focused$ = new DS.Listenable(false);
  constructor(player: IPlayer, classname = "") {
    super(player, classname);

    this.durationDOM = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.progressBarContainer,
      className: "duration",
    });

    // player.duration$ don't update if player is paused.
    // We need to do this manually !
    player.state$.didChange(value => {
      if (value === PlayerState.PAUSED && this._timeTrack === -1) {
        this._timeTrack = window.setInterval(() => {
          player.duration$.value = Date.now() / 1000;
        }, 1000);
      } else {
        clearInterval(this._timeTrack);
        this._timeTrack = -1;
      }
    }, this);

    player.asset$.didChange(
      value => {
        if (value?.resource && value.resource.live?.end !== undefined) {
          this.startDate = value.resource.live.start;
          this.endDate = value.resource.live?.end;
          this.time();
        }
        this._rejectsFocus = this.player.asset$.value?.isFastTV === true ?? false;
      },
      this,
      true
    );

    this._focused$.didChange(this.update.bind(this), this);
    player.duration$.didChange(this.update.bind(this), this);
    player.currentTime$.didChange(this.update.bind(this), this);
    player.seekTime$.didChange(this.update.bind(this), this);
  }

  time() {
    this.currentTimeDOM.innerText = getFormatDate(this.startDate);
    this.totalTimeDOM.innerText = getFormatDate(this.endDate);
  }

  update(): void {
    let currentTime = 0;
    if (this.player.seekTime$.value !== undefined && this.player.seekTime$.value !== 0) {
      currentTime = this.player.seekTime$.value * 1000;
    } else if (this.player.currentTime$.value > 0) {
      currentTime = this.player.currentTime$.value * 1000;
    } else {
      return;
    }
    const duration = this.endDate.getTime() - this.startDate.getTime();
    // Calculate the ellapsed time.
    const ellapsed = Math.max(0, Math.trunc(currentTime) - this.startDate.getTime());
    // Set the width of the progress, depending on where we are.
    this.progressDOM.style.width = `${(ellapsed / duration) * 100}%`;
    // Calculate the actual live time.
    const live = Math.trunc(this.player.duration$.value * 1000) - this.startDate.getTime();
    // Allow 1s of delay for live
    if (live - duration > 0) {
      this.durationDOM.style.width = `calc(100% - ${this.progressDOM.style.width} - ${
        (this._focused$.value ? SCRUBBER_SIZE : 0) + 1
      }px)`;
    } else if (live - ellapsed > 1000 && this.player.asset$.value?.isFastTV !== true) {
      const percentage = `${((live - ellapsed) / duration) * 100}%`;
      this.durationDOM.style.width = this._focused$.value ? `calc(${percentage} - ${SCRUBBER_SIZE}px)` : percentage;
    } else {
      this.durationDOM.style.width = "0px";
    }
    this.durationDOM.style.maxWidth = `max(calc(100% - ${this.progressDOM.style.width} - ${
      (this._focused$.value ? SCRUBBER_SIZE : 0) + 1
    }px + 22px), 0)`;
    // If we are at the end, generate again new dates. And everything will update.
    // If we come back to a previous time, update the player.
    if (this.startDate.getTime() + ellapsed > this.endDate.getTime()) {
      this.time();
    } else if (currentTime !== 0 && currentTime < this.startDate.getTime()) {
      this.time();
    }
  }

  onFocused() {
    this._focused$.value = true;
  }

  onUnfocused() {
    this._focused$.value = false;
  }

  onRelease() {
    super.onRelease();
    window.clearInterval(this._timeTrack);
  }

  rejectsFocus = () => this._rejectsFocus;
}

/**
 * The Radio live progress bar
 * Update the progress depending on the player current time and duration.
 */
export class PlayerAudioLiveProgressBar extends PlayerProgressBar {
  constructor(player: IPlayer) {
    super(player);
    // Callbacks
    player.currentTime$.didChange(this.update.bind(this), this, true);
    player.seekTime$.didChange(this.update.bind(this), this, true);
    this.totalTimeDOM.innerText = t("player.radio.direct");
    this.update();
  }

  update() {
    const minutesEllapsed = convertSecondToMinutes(this.player.duration$.value ?? 0);
    this.currentTimeDOM.innerText = `-${Math.floor(minutesEllapsed)}${t("player.radio.min")}`;
    this.progressDOM.style.width = `100%`;
  }

  onNav(key: DS.Keys): boolean {
    switch (key) {
      case DS.Keys.right:
      case DS.Keys.left:
        if (this.player.duration$.value < 90) {
          return true;
        }
        break;
    }
    return super.onNav(key);
  }
}

/**
 * The Radio live progress bar
 * Update the progress depending on the player current time and duration.
 */
export class PlayerAudioShiftProgressBar extends PlayerProgressBar {
  // Minute à partir on considere que le shift est "stable", et que les calculs ne
  // provoqueront pas des décalements étrange
  private static _minuteStable = 10;
  private _shiftTimeDOM: ITextElement;
  private _ratio: string | undefined;
  constructor(player: IPlayer) {
    super(player);
    this._shiftTimeDOM = TextHelper.createTextEmpty(this.rootElement, "shiftTime");
    // Callbacks
    player.currentTime$.didChange(this.update.bind(this), this);
    player.seekTime$.didChange(this.update.bind(this), this);
    this.totalTimeDOM.innerText = t("player.meta.live");
  }

  update() {
    const time = this.player.seekTime$.value ?? this.player.currentTime$.value;

    // Nombre de minutes actuel au dessus du curseur
    const currentMinutes = convertSecondToMinutes(time);

    // Nombre de minutes qui se sont passé à gauche
    const minutesEllapsed = convertSecondToMinutes(this.player.duration$.value);

    // Position du curseur
    if (this.player.seekTime$.value === undefined && minutesEllapsed < PlayerAudioShiftProgressBar._minuteStable) {
      // this.progressDOM.style.width = `${100 - Math.floor((currentMinutes / minutesEllapsed) * 100)}%`;
      this.progressDOM.style.width = this._ratio ?? `50%`;
    } else {
      this.progressDOM.style.width = `${(time / this.player.duration$.value) * 100}%`;
    }

    // Keeping the width of the progress bar after seeking
    if (this.player.seekTime$.value !== undefined) {
      this._ratio = this.progressDOM.style.width;
    }

    // Positionnement du nombre de minutes actuelles
    this.currentTimeDOM.innerText = `-${minutesEllapsed}${t("player.radio.min")}`;
    // Positionnement du nombre de minutes total à gauche
    this._shiftTimeDOM.update(`-${minutesEllapsed - currentMinutes}${t("player.radio.min")}`);
    this._shiftTimeDOM.get().style.left = `${
      227 - 56.5 + parseFloat(window.getComputedStyle(this.progressDOM).width)
    }px`;
  }

  protected _move(direction: MoveDirection, release = false): void {
    if (release) {
      return;
    }
    this.player.pause();
    this.player.seek(
      direction === MoveDirection.left ? this.player.TIME_INCREMENT_RW : this.player.TIME_INCREMENT_FW,
      true
    );
    this._moveTime = Date.now();
  }
}

/**
 * Represents the calculated widths of the progress bars for a media player.
 */
type ProgressWidths = {
  fakeProgressWidth: number; // Width of the fake progress bar as a percentage.
  rewindProgressWidth: number; // Width of the rewindable progress bar as a percentage.
  playbackProgressWidth: number; // Width of the progress bar indicating playback duration as a percentage.
};

/**
 * Live progress bar.
 * Logic implemented to handle live case and time shifting case.
 */
export class PlayerAdReplacementProgressBar extends PlayerVideoLiveProgressBar {
  private _fakeProgressDOM: HTMLElement;
  private _pauseDOM: HTMLElement;
  constructor(player: IPlayer) {
    super(player, "adReplacement");

    this._fakeProgressDOM = DS.DOMHelper.createElement({
      tagName: "div",
      className: "fakeProgress",
    });
    this.progressBarContainer.insertBefore(this._fakeProgressDOM, this.progressBarContainer.firstChild);
    this._pauseDOM = DS.DOMHelper.createElement({
      tagName: "span",
      parent: this.rootElement,
      className: "pauseAdReplacement",
      innerText: t(`player.adReplacement.pause`).replace("MINUTES", PlayerCommon._AD_REPLACEMENT_MAX_PAUSE.toString()),
    });

    player.state$.didChange(
      value => {
        if (value === PlayerState.PAUSED) {
          this._pauseDOM.style.display = "inline";
        } else {
          this._pauseDOM.style.display = "none";
        }
      },
      this,
      true
    );
  }

  /**
   * Calculates the widths for the progress bars (rewindable, fake, and playback progress).
   * @returns {ProgressWidths} An object containing `fakeProgressWidth`, `rewindProgressWidth`, and `playbackProgressWidth`.
   */
  private _computeProgressBarWidths(): ProgressWidths {
    const MAX_REWIND_DURATION = PlayerCommon._AD_REPLACEMENT_MAX_PAUSE * 60 * 1000;

    // Calculate the total duration of the live
    const totalLiveDuration = this.endDate.getTime() - this.startDate.getTime();

    // Calculate the duration reported by the player
    const contentDuration = this.player.duration$.value * 1000;

    // Calculate the elapsed time since the start of the live, based on the player's duration
    const elapsedLiveDuration = contentDuration - this.startDate.getTime();

    // Initialize progress bar variables
    const baseRewindProgressWidth = (MAX_REWIND_DURATION / totalLiveDuration) * 100;

    let rewindProgressWidth = baseRewindProgressWidth;
    let fakeProgressWidth = 0;
    let playbackProgressWidth = 0;

    /**
     * Handle the width of the rewindable progress bar
     */

    // Case: Live has started, and total duration is less than the max rewindable duration
    if (contentDuration < this.startDate.getTime() + MAX_REWIND_DURATION) {
      const effectiveRewindTime = Math.min(elapsedLiveDuration, MAX_REWIND_DURATION);
      rewindProgressWidth = (effectiveRewindTime / totalLiveDuration) * 100;
    }

    // Case: Live has ended, and total duration exceeds the end time
    if (contentDuration > this.endDate.getTime()) {
      const timePastEnd = contentDuration - this.endDate.getTime();
      const remainingTime = Math.max(MAX_REWIND_DURATION - timePastEnd, 0);
      rewindProgressWidth = (remainingTime / totalLiveDuration) * 100;
    }

    /**
     * Handle the width of the fake progress bar
     */
    fakeProgressWidth = (elapsedLiveDuration / totalLiveDuration) * 100;
    if (fakeProgressWidth > 100) fakeProgressWidth = 100;

    /**
     * Determine the current playback time
     */
    let currentPlaybackTime = 0;
    if (this.player.seekTime$.value !== undefined && this.player.seekTime$.value !== 0) {
      // Use the seek time if available
      currentPlaybackTime = this.player.seekTime$.value * 1000;
    } else if (this.player.currentTime$.value > 0) {
      // Otherwise, use the current playback time
      currentPlaybackTime = this.player.currentTime$.value * 1000;
    } else {
      throw new Error(
        "Failed to determine the current playback time: both 'seekTime$' and 'currentTime$' are unavailable or invalid."
      );
    }

    /**
     * Verify if the live elapsed time significantly exceeds the reported elapsed time
     */

    // Calculate the live elapsed time since the start
    const liveElapsedTime = Math.max(0, Math.trunc(currentPlaybackTime) - this.startDate.getTime());

    if (elapsedLiveDuration - liveElapsedTime > 1000) {
      /**
       * Use static rewind progress width to prevent the progress duration progress bar from shrinking too much at the end of a live show in the rewind progress bar
       */
      if (contentDuration > this.endDate.getTime()) {
        playbackProgressWidth =
          baseRewindProgressWidth - ((elapsedLiveDuration - liveElapsedTime) / totalLiveDuration) * 100;
      } else {
        playbackProgressWidth =
          rewindProgressWidth - ((elapsedLiveDuration - liveElapsedTime) / totalLiveDuration) * 100;
      }
    } else {
      // When seektime is superior to duration
      playbackProgressWidth = rewindProgressWidth;
      this.durationDOM.style.width = "0px";
    }

    if (fakeProgressWidth < rewindProgressWidth) {
      playbackProgressWidth = (liveElapsedTime / totalLiveDuration) * 100;
    }

    /**
     * Making sure playbackProgressWidth respect min / max values
     */
    if (playbackProgressWidth < 0) playbackProgressWidth = 0;
    if (playbackProgressWidth > rewindProgressWidth) playbackProgressWidth = rewindProgressWidth;

    return {
      fakeProgressWidth,
      rewindProgressWidth,
      playbackProgressWidth: playbackProgressWidth,
    };
  }

  /**
   * Updates the DOM elements representing the progress bars with calculated widths.
   * @param progressWidths - An object containing `fakeProgressWidth`, `rewindProgressWidth`, and `playbackProgressWidth`.
   * @returns {void}
   */
  private _applyProgressWidthsToDOM(progressWidths: ProgressWidths): void {
    const { fakeProgressWidth, rewindProgressWidth, playbackProgressWidth } = progressWidths;

    this._fakeProgressDOM.style.width = `${fakeProgressWidth - rewindProgressWidth}%`;
    this.progressDOM.style.width = `${playbackProgressWidth}%`;
    this.durationDOM.style.width = `calc(${rewindProgressWidth - playbackProgressWidth}% - ${
      this._focused$.value ? SCRUBBER_SIZE : 0
    }px)`;
  }

  /**
   * Updates the position of the displayed number of minutes based on progress bar widths.
   */
  private _repositionPauseIndicator() {
    if (this.player.state$.value !== PlayerState.PAUSED) return;
    // Positioning of the total number of minutes on the left
    this._pauseDOM.style.left = `${
      227 -
      parseFloat(window.getComputedStyle(this._pauseDOM).width) / 2 +
      parseFloat(window.getComputedStyle(this._fakeProgressDOM).width) +
      parseFloat(window.getComputedStyle(this.progressDOM).width)
    }px`;
  }

  /**
   * Main update method to calculate and apply progress bar widths and update the pause indicator position.
   */
  update() {
    try {
      const progressWidths = this._computeProgressBarWidths();

      this._applyProgressWidthsToDOM(progressWidths);

      this._repositionPauseIndicator();
    } catch (error: unknown) {
      return;
    }
  }
}
