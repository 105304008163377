import "./mediaContentView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";
import { DS } from "~/libs";
import { OffrePremiumPage } from "~/pages/settings/offrePremium/offrePremiumPage";
import { PremiumHelper } from "~/tools/premiumHelper";

import { Config } from "../../../config";
import { GenericPage } from "../../../pages/generic/genericPage";
import { PlayHistoryHelper } from "../../../tools/playHistoryHelper";
import { getAvailableDaysText, getPublishedText, getRemainingDays } from "../../../tools/time";
import { PageMediaContent } from "../../../utils/rtbf/models";
import {
  ClassType,
  ContentButton,
  ContentButtonsContainerView,
  ContentButtonWidgetType,
} from "../../views/content/contentButtonView/contentButtonView";
import { ContentCategoryChannelView } from "../../views/content/contentCategoryChannelView/contentCategoryChannelView";
import { ContentDescriptionView } from "../../views/content/contentDescriptionView/contentDescriptionView";
import { ContentMetaView } from "../../views/content/contentMetaView/contentMetaView";
import { ContentSubtitleView } from "../../views/content/contentSubtitleView/contentSubtitleView";
import { ContentTimelineView } from "../../views/content/contentTimelineView/contentTimelineView";
import { ContentTitleView } from "../../views/content/contentTitleView/contentTitleView";
import { ContentItem } from "../contentView";
import { EmptyContentView } from "../empty/emptyContentView";

export class MediaContentView extends AcceptsMouseFocusView {
  private _list: DS.IListComponent<
    ContentItem,
    | ContentCategoryChannelView
    | ContentTitleView
    | ContentSubtitleView
    | EmptyContentView
    | ContentDescriptionView
    | ContentButtonsContainerView
  >;
  private _mediaContentList: ContentItem[] = [];
  private _classType;
  private _modelSource$: DS.Listenable<ContentItem[]>;
  private _buttons: ContentButton[] = [];
  private _content;
  private _contentButtonsContainerView?: ContentButtonsContainerView;
  private _purchases$;
  private _offsetListener$;

  constructor(content: PageMediaContent) {
    super(content.id, `mediaContentView ${content.isPremium === true && "contentPremium premiumFocus"}`);
    this._content = content;
    void PremiumHelper.updatePurchases("purchases");
    this._purchases$ = PremiumHelper.getPurchases();

    let textDuration = "";

    if (content.isPremium === true) {
      this._classType = ClassType.premium;
    } else {
      this._classType = ClassType.media;
    }

    const offsetListener$ = PlayHistoryHelper.getCurrentOffset(content.id);
    this._offsetListener$ = PlayHistoryHelper.getCurrentOffset(content.id);

    this._mediaContentList.push(
      ContentItem.categoryChannel,
      content.logo !== null ? ContentItem.titleGraphic : ContentItem.title,
      ContentItem.subtitle,
      ContentItem.meta,
      ContentItem.timeline,
      ContentItem.contentButtonsContainer,
      ContentItem.description
    );

    const remainingDays = getRemainingDays(new Date(content.publishedTo), new Date());
    const availableDays = getAvailableDaysText(new Date(content.publishedTo));
    if (content.publishedFrom !== null) textDuration = getPublishedText(new Date(content.publishedFrom));

    this.delegate = this._list = DS.createListComponent(
      {
        id: "mediaContentList",
        className: "mediaContentList",
        modelSource$: (this._modelSource$ = new DS.ModelSource(this._mediaContentList)),
        viewFactory: item => {
          switch (item) {
            case ContentItem.categoryChannel:
              return new ContentCategoryChannelView(this._classType, content.channel?.label, content.category?.label);
            case ContentItem.title:
              return new ContentTitleView(content.title);
            case ContentItem.subtitle:
              return new ContentSubtitleView(content.subtitle);
            case ContentItem.meta:
              return new ContentMetaView({
                csaRating: content.rating ?? null,
                hasMultilingual: content.hasMultilingualVersions,
                hasSubtitle: content.hasSubtitles,
                hasAudio: content.hasAudioDescriptions,
                duration: content.duration,
                textDuration: textDuration,
                publishedTo: {
                  text: availableDays,
                  unavailableSoon: remainingDays <= 7,
                },
              });
            case ContentItem.description:
              return new ContentDescriptionView(content.description);
            case ContentItem.contentButtonsContainer:
              return (this._contentButtonsContainerView = new ContentButtonsContainerView(
                (this._buttons = this._buttonsList()),
                content,
                this._classType
              ));
            case ContentItem.timeline:
              return new ContentTimelineView({
                duration: content.duration,
                mediaId: content.id,
                type: "MEDIA",
              });
            default:
              return new EmptyContentView();
          }
        },
        scrollingMode: { type: DS.ScrollingType.elasticWindow, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
      },
      list => {
        list.shown$.didChange(shown => {
          if (shown === true) void list.refresh();
        }, this);
        void list.setFocusOnIndex(0);
      }
    );

    offsetListener$.didChange(_ => this._updateButtonsView(), this, true);

    this._purchases$.didChange(_ => this._updateButtonsView(), this, true);
  }

  /**
   * Generate buttons modelSource
   * @returns ContentButton[]
   */
  private _buttonsList(): ContentButton[] {
    const buttons: ContentButton[] = [];

    let showPlayButton = true;
    let showSubscribeButton = false;

    if (this._content.isPremium === true && APIGigyaOIDC.isConnected() !== false) {
      if (PremiumHelper.isPurchased(this._content.products) === false) {
        showPlayButton = false;
        showSubscribeButton = true;
      }
    }

    if (showPlayButton === true) {
      buttons.push({
        id: "PlayContentButton",
        type: ContentButtonWidgetType.playContentButton,
        content: this._content,
      });

      this._offsetListener$.value > 0 &&
        buttons.push({
          id: "StovContentButton",
          type: ContentButtonWidgetType.stovContentButton,
          content: this._content,
        });
    }

    if (showSubscribeButton === true) {
      buttons.push({
        id: "SubscribeContentButton",
        type: ContentButtonWidgetType.genericContentButton,
        genericLink: {
          id: "subscribe",
          label: t("button.subscribe_title"),
          icon: "subscribe",
          page: () => {
            return new OffrePremiumPage();
          },
        },
      });
    }

    if (this._content.program) {
      const path = this._content.program.path;
      buttons.push({
        id: `GenericContentButton${this._content.id}`,
        type: ContentButtonWidgetType.genericContentButton,
        genericLink: {
          id: `episodeButton${this._content.id}`,
          label: t("button.allEpisodes_title"),
          page: () => {
            return new GenericPage(path);
          },
        },
      });
    }
    buttons.push({
      id: "FavoriteContentButton",
      type: ContentButtonWidgetType.favoriteContentButton,
      content: this._content,
    });
    buttons.push({
      id: "InfoContentButton",
      type: ContentButtonWidgetType.infoContentButton,
      content: this._content,
    });

    if (buttons !== this._buttons) {
      this._contentButtonsContainerView?.updateButtons(buttons);
      this._buttons;
    }

    return buttons;
  }

  private _updateButtonsView(): void {
    const buttons = this._buttonsList();
    if (buttons !== this._buttons) {
      this._buttons = buttons;
      this._contentButtonsContainerView?.updateButtons(buttons);
    }
  }
}
