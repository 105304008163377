import "./didomiPopupPage.scss";

import { DS } from "dslib-tv";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { exitApp, navigationStack } from "~/main";
import { TrackingHelper } from "~/tools/trackingHelper";
import { Didomi } from "~/typings/didomi";
import { areAllPartnersAccepted, resetDidomiLocalInformations, showDidomiPage } from "~/utils/didomi/didomi";

const VISIBLE_NOTICE_CLASS_NAME = "visible";
const DIDOMI_DOM_ELEMENT_SELECTOR = "#didomi-host";

export class DidomiPopupPageView extends AcceptsMouseFocusView implements DS.IPage {
  private _didomiInstance: Didomi;
  /**
   * This boolean is used to avoid firing multiple time API.signin method in the setInterval
   * callback.
   **/
  private _isSigningIn = false;
  private _options;
  private _preferencesHiddenTimestamp: number | null = null;

  constructor(didomi: Didomi, options: { context: "settings" } | { context: "startup"; onClose: () => void }) {
    super("DidomiPopup", "didomiPopup");

    this._didomiInstance = didomi;
    this._options = options;
    Log.app.log("USER STATUS: ", didomi.getCurrentUserStatus());

    if (options.context === "startup") {
      /*
       * NOTE: I don't know why I need to hide the preferences to show the notice
       * The second line is useless in my experience, but I'd rather keep it since I don't understand what's going on
       **/
      this._didomiInstance.preferences.hide();
      this._didomiInstance.notice.show();
    } else if (options.context === "settings") {
      this._didomiInstance.preferences.show("purposes");
    }

    /**
     * Check if Didomi has been closed by the user and close the popup if so.
     *
     * We need to use a timer since there is no other way to detect that user quit
     * Didomi page by pressing "back" or "accept" / "deny" the consent.
     */
    const isClosedInterval = window.setInterval(() => {
      if (this._isSigningIn === true) return;
      const shouldConsentBeCollected = didomi?.shouldConsentBeCollected?.() ?? false;

      if (
        options.context === "startup" &&
        this._didomiInstance.notice.isVisible() === false &&
        this._didomiInstance.preferences.isVisible() === false
      ) {
        this._onCloseDidomi(shouldConsentBeCollected, options.onClose);
      } else if (options.context === "settings" && this._didomiInstance.preferences.isVisible() === false) {
        this._onCloseDidomi(shouldConsentBeCollected);
      }
    }, 500);

    this.collectRelease(() => {
      window.clearInterval(isClosedInterval);
    });

    this._didomiInstance.on("preferences.hidden", () => (this._preferencesHiddenTimestamp = Date.now()));
  }

  private _onCloseDidomi(shouldConsentBeCollected: boolean, onClose?: () => void) {
    this._isSigningIn = true;
    navigationStack.removePage(this);

    onClose?.();

    if (shouldConsentBeCollected === false) {
      if (!areAllPartnersAccepted()) {
        TrackingHelper.reLoadChartbeat();
      } else {
        TrackingHelper.reLoadChartbeat(false);
      }
    }
  }

  isPopup = () => {
    return true;
  };

  private _showExitPopup() {
    navigationStack.removePage(this);
    exitApp({
      onCancel: () => {
        resetDidomiLocalInformations();
        showDidomiPage();
      },
      isFullPage: true,
    });
  }

  onNav = (key: DS.Keys): boolean => {
    // Ignore back navigation if not in the "startup" context
    if (this._options.context !== "startup" || key !== DS.Keys.back) {
      return true;
    }

    // If the preferences page is visible, we are not on the index (home) page.
    // Similarly, if the notice page is not visible, we are also not on the index.
    // In either case, ignore this back navigation event.
    if (!this._didomiInstance.notice.isVisible() || this._didomiInstance.preferences.isVisible()) {
      return true;
    }

    // If the preferences hidden timestamp is undefined, it means the app was just launched.
    // Show the exit popup immediately in this case.
    if (this._preferencesHiddenTimestamp === null) {
      this._showExitPopup();
      return true;
    }

    // Use the timestamp difference to prevent showing the exit popup too soon
    // after the user exits the preferences page (caused by Didomi handling escape internally).
    if (!this._shouldHandleBackPress()) {
      // If the delay hasn't passed, ignore this back navigation event.
      return true;
    }

    // Handle valid back press on the root notice page by showing the exit popup
    this._showExitPopup();
    return true;
  };

  /**
   * Determines whether the back press event should be handled based on the time elapsed
   * since the preferences page was last hidden.
   *
   * @returns {boolean} `true` if enough time has passed since the preferences page was hidden,
   *                    allowing the back press to be handled. Otherwise, `false`.
   */
  private _shouldHandleBackPress(): boolean {
    const currentTimestamp = Date.now();
    const timeSinceHidden = currentTimestamp - (this._preferencesHiddenTimestamp ?? 0);
    const BACK_PRESS_DEBOUNCE_DELAY = 200;

    return timeSinceHidden > BACK_PRESS_DEBOUNCE_DELAY;
  }

  onShown() {
    const didomiElement = document.querySelector(DIDOMI_DOM_ELEMENT_SELECTOR);

    if (didomiElement !== null) {
      /**
       * This allow Didomi Dom Element to be visible and set back its z-index
       */
      didomiElement.classList.add(VISIBLE_NOTICE_CLASS_NAME);
    }
  }

  onHidden() {
    const didomiElement = document.querySelector(DIDOMI_DOM_ELEMENT_SELECTOR);

    if (didomiElement !== null) {
      /**
       * This allow Didomi Dom Element to be hidden
       */
      didomiElement.classList.remove(VISIBLE_NOTICE_CLASS_NAME);
    }
  }
}
