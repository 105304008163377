import { DS } from "dslib-tv";

import { APIAuvio } from "~/datas/api/apiAuvio";

import { APIGigyaOIDC } from "../datas/api/apiGigyaOIDC";
import { navigationStack } from "../main";
import { GenericIncentivePageFull, IncentiveType } from "../pages/incentive/incentivePage";
import { PageMediaContent, PageProgramContent, UserFavorites } from "../utils/rtbf/models";
import { Toast } from "./uiHelper";

/**
 * Manages user favorites (programs and media) in the application.
 *
 * This singleton class provides functionalities for handling user favorites,
 * including adding, removing, and checking favorite items. It also maintains
 * lists of program and media IDs and synchronizes these with a combined
 * list of all favorite IDs.
 */
class FavoriteManager {
  private static _instance?: FavoriteManager;
  private _favoriteIds: string[] = [];

  private _programIds: UserFavorites["programs"] = [];
  private _mediaIds: UserFavorites["medias"] = [];

  length$ = new DS.Listenable<number>(0);

  static get instance() {
    return (FavoriteManager._instance = FavoriteManager._instance ?? new FavoriteManager());
  }

  get programIds(): UserFavorites["programs"] {
    return this._programIds;
  }

  set programIds(newProgramIds: UserFavorites["programs"]) {
    this._programIds = newProgramIds;
    this._updateFavoriteIds();
  }

  get mediaIds(): UserFavorites["medias"] {
    return this._mediaIds;
  }

  set mediaIds(newMediaIds: UserFavorites["medias"]) {
    this._mediaIds = newMediaIds;
    this._updateFavoriteIds();
  }

  get favoriteIds() {
    return this._favoriteIds;
  }

  /**
   * Update both program and media IDs from a single source.
   * @param favoriteIds - The updated favorite IDs (programs and medias).
   */
  public setFavoriteIds(favoriteIds: UserFavorites) {
    this._programIds = favoriteIds.programs;
    this._mediaIds = favoriteIds.medias;
    this._updateFavoriteIds();
  }

  /**
   * Add a favorite program or media to the user's favorites list.
   * Prompts for login if the user is not connected.
   * @param asset - The program or media to add as a favorite.
   * @returns A promise that resolves to `true` if the operation was successful, `false` otherwise.
   */
  async addFavorite(asset: PageProgramContent | PageMediaContent): Promise<boolean> {
    if (APIGigyaOIDC.isConnected() === false) {
      navigationStack.pushPage(new GenericIncentivePageFull(IncentiveType.connexion));
      return false;
    }

    const programId =
      asset.pageType === "MEDIA" && asset.program !== null && !this._favoriteIds.includes(asset.program?.id)
        ? asset.program?.id
        : undefined;

    const response = await APIAuvio.addFavorite(asset.pageType, asset.id, programId);
    if (response === true) {
      this._addToFavoriteLists(asset.pageType, asset.id);

      // Add a media-related program
      if (programId !== undefined) {
        this._addToFavoriteLists("PROGRAM", programId);
      }

      Toast(t(`toaster.addFav_${asset.pageType}_text`));
    } else {
      Toast(t(`toaster.addFav_error_text`));
    }

    return response;
  }

  /**
   * Remove a favorite program or media from the user's favorites list.
   * @param asset - The program or media to remove from favorites.
   * @returns A promise that resolves to `true` if the operation was successful, `false` otherwise.
   */
  async removeFavorite(asset: PageProgramContent | PageMediaContent): Promise<boolean> {
    const response = await APIAuvio.removeFavorite(asset.pageType, asset.id);

    if (response === true) {
      this._removeFromFavoriteLists(asset.pageType, asset.id);
      Toast(t(`toaster.delFav_${asset.pageType}_text`));
    } else {
      Toast(t(`toaster.delFav_error_text`));
    }

    return response;
  }

  /**
   * Private helper to add an ID to the appropriate favorite lists.
   * @param type - The type of asset ("MEDIA" or "PROGRAM").
   * @param id - The ID of the asset to add.
   */
  private _addToFavoriteLists(type: "MEDIA" | "PROGRAM", id: string) {
    if (type === "MEDIA") {
      if (!this._mediaIds.includes(id)) {
        this._mediaIds.push(id);
      }
    } else if (type === "PROGRAM") {
      if (!this._programIds.includes(id)) {
        this._programIds.push(id);
      }
    }

    this._updateFavoriteIds();
  }

  /**
   * Private helper to remove an ID from the appropriate favorite lists.
   * @param type - The type of asset ("MEDIA" or "PROGRAM").
   * @param id - The ID of the asset to remove.
   */
  private _removeFromFavoriteLists(type: "MEDIA" | "PROGRAM", id: string) {
    if (type === "MEDIA") {
      const mediaIndex = this._mediaIds.indexOf(id);
      if (mediaIndex > -1) {
        this._mediaIds.splice(mediaIndex, 1);
      }
    } else if (type === "PROGRAM") {
      const programIndex = this._programIds.indexOf(id);
      if (programIndex > -1) {
        this._programIds.splice(programIndex, 1);
      }
    }

    this._updateFavoriteIds();
  }

  /**
   * Check if a given ID is marked as a favorite.
   * @param favoriteId - The ID to check.
   * @returns `true` if the ID is a favorite, `false` otherwise.
   */
  isFavorite(favoriteId: string): boolean {
    return this._favoriteIds.includes(favoriteId);
  }

  /**
   * Clear all favorite lists (programs, media, and combined favorites).
   */
  clearFavoriteLists() {
    this._favoriteIds = [];
    this._programIds = [];
    this._mediaIds = [];
    this.length$.value = 0;
  }

  /**
   * Private helper to synchronize the combined list of favorites (`_favoriteIds`)
   * with the current lists of program and media IDs.
   */
  private _updateFavoriteIds() {
    this._favoriteIds = [...this._programIds, ...this._mediaIds];
    this.length$.value = this._favoriteIds.length;
  }
}

export const FavoriteHelper = FavoriteManager.instance;
