import { z } from "zod";

import { Response } from "./base";

// DELETE FAVORITE
export const deleteFavoriteResponse = Response.extend({
  status: z.number(),
});

// GET USER FAVORITES
const GetUserFavoritesResult = z.object({
  medias: z.array(z.string()).default([]),
  programs: z.array(z.string()).default([]),
});

export type UserFavorites = z.infer<typeof GetUserFavoritesResult>;

export const GetUserFavoritesResponse = Response.extend({
  data: GetUserFavoritesResult,
});
