/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { DS } from "~/libs";
import { PlatformType } from "~/libs/exports";
import { GenericPage } from "~/pages/generic/genericPage";

import { Config, env } from "../datas";
import { APIGigyaOIDC } from "../datas/api/apiGigyaOIDC";
import { navigationStack } from "../main";
import {
  SnowplowBaseEventParams,
  SnowplowEventsMapping,
  SnowplowPayload,
  SnowplowPlayerEventName,
} from "../typings/snowplow";
import { RTBF } from "../utils/rtbf";
import { PageContent, RtbfEventName, TrackingData } from "../utils/rtbf/models";
import { IPlayerAsset } from "./player";
import { loadJS } from "./snippets/scripts";
import { SnowplowHelper } from "./snowplowHelper";

interface ITrackingData {
  event_name: RtbfEventName;
  path?: string;
  gemius?: RTBF.Gemius;
  chartbeat?: {
    asset: PageContent;
    meta?: RTBF.Chartbeat;
  };
  gtag: Partial<TrackingData>;
}

/* TODO: Move Gemius types in a typing file (warning, they are already declared in utils/rtbf/models/base with different data...) */
type GemiusPlayer = {
  setVideoObject: (...args: unknown[]) => void;
  newProgram: (...args: unknown[]) => void;
  newAd: (...args: unknown[]) => void;
  programEvent: (...args: unknown[]) => void;
  adEvent: (...args: unknown[]) => void;
  dispose: () => void;
};
type GemiusPlayerConstructor = {
  new (playerID: string, gemiusID: string, playerData?: unknown): GemiusPlayer;
};

export enum GemiusPlayerEvent {
  play = "play",
  pause = "pause",
  buffer = "buffer",
  seek = "seek",
  close = "close",
  complete = "complete",
}

export const playerEventsMapping = {
  paused: SnowplowPlayerEventName.pause,
  playing: SnowplowPlayerEventName.resume,
  seeking: SnowplowPlayerEventName.seeking,
  ended: SnowplowPlayerEventName.ended,
} as const;

declare global {
  interface Window {
    // Gemius
    // pp_gemius_identifier: string;
    gemius_pending: (arg: string) => void;
    pp_gemius_hit: (identifier: string, ...extraparameters: string[]) => void;
    GemiusPlayer: GemiusPlayerConstructor;
    // GTAG
    gtag: (...args: unknown[]) => void;
    // eslint-disable-next-line @typescript-eslint/ban-types
    dataLayer: Array<object>;
    pp_gemius_use_cmp: number;
    pp_gemius_dnt: number;
  }
}

class TrackingManager {
  private static _instance?: TrackingManager;
  private _chartbeatData?: RTBF.ChartbeatData;
  private _gemiusData?: RTBF.GemiusData;
  private _sf_async_config = (window._sf_async_config = window._sf_async_config || {});
  private _gemiusPlayer: GemiusPlayer | undefined;
  private _lastProgramEvent: string | undefined = undefined;
  private _UIDSuffix = Math.random().toString(36).substring(2, 9);

  pageType = "";
  pageReferrerPath = "";
  pageId = "";
  pageTitle = "";
  currentMediaId: string | undefined = undefined;

  /* NOTE: Code kept if client change is mind after backend can't handle the events load
  private _pageLoadTimestamp = Date.now();
  */

  static get instance() {
    return (TrackingManager._instance = TrackingManager._instance ?? new TrackingManager());
  }

  get chartbeatData() {
    return this._chartbeatData;
  }

  get gemiusData() {
    return this._gemiusData;
  }

  /* NOTE: Code kept if client change is mind after backend can't handle the events load
  get pageLoadTimestamp(): number {
    return this._pageLoadTimestamp;
  }
  */

  public initChartbeatData(chartbeatData: RTBF.ChartbeatData, path: string) {
    this._chartbeatData = chartbeatData;

    /**
     * Chartbeat tracking for desktop and mobile web
     */
    this.configureChartbeat(false, path);
    this.loadChartbeat();
  }

  public initGemiusData(gemiusData: RTBF.GemiusData) {
    this._gemiusData = gemiusData;

    this._configureGemius();
    void loadJS(`https://gabe.hit.gemius.pl/xgemius.js`);
  }

  public async initGemiusPlayer(player: HTMLElement) {
    await loadJS(`https://gabe.hit.gemius.pl/gplayer.js`);
    this._gemiusPlayer = new window.GemiusPlayer(`video`, this.gemiusData?.id.player ?? "no_id");
    this._gemiusPlayer?.setVideoObject(player);
  }

  public disposeGemiusPlayer() {
    this._gemiusPlayer?.dispose();
    this._gemiusPlayer = undefined;
  }

  public initGtagData(gtagId: string): void {
    window.dataLayer = window.dataLayer || [];
    // create datalayer right away. Script will be loaded as needed, once we have the settings
    if (window.gtag === undefined) {
      // do the structure init
      window.gtag = function () {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments);
      };
      window.gtag("js", new Date());
    }
    window.gtag("config", gtagId, {
      send_page_view: false,
      debug_mode: Config().GTAG.debug,
    });
    void loadJS(`https://www.googletagmanager.com/gtag/js?id=${gtagId}`);
  }

  /**
   * Sets a list of configuration variables that determine how we track your site.
   */
  configureChartbeat(reset = false, path?: string) {
    /** CONFIGURATION START **/
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions

    // The UID variable needs to be assigned to your unique Chartbeat account ID, available from your Account Executive or Customer Success Manager.
    this._sf_async_config.uid = reset ? "" : this._chartbeatData?.accountId;
    // The domain variable should be assigned to your site name (usually the root domain). If implementing for more than one site, the domain variable in this code should also be updated to the root domain of each unique site.
    this._sf_async_config.domain = reset ? "" : this._chartbeatData?.siteId;
    this._sf_async_config.flickerControl = false;
    this._sf_async_config.useCanonical = true;
    this._sf_async_config.useCanonicalDomain = true;
    // This is because we are an application and not a website
    this._sf_async_config.mobileApp = true;

    if (path) this._sf_async_config.path = path;
    /** CONFIGURATION END **/
  }

  /**
   * Creates a <script> element that starts asynchronously downloading the chartbeat.js tracker from static.chartbeat.com/js/chartbeat.js
   */
  loadChartbeat() {
    const script = document.createElement("script");
    script.id = "ChartBeat";
    script.type = `text/javascript`;
    script.src = `//static.chartbeat.com/js/chartbeat.js`;
    script.async = true;
    document.head.appendChild(script);
  }

  /**
   * Remove script config tag for chartbeat thus disabling it
   */
  reLoadChartbeat(reset = true) {
    this.configureChartbeat(reset);
    console.error("[UNLOAD DONE]");
    const script = document.head.querySelector("#ChartBeat");
    console.error("script", script);
    script?.remove();
    this.loadChartbeat();
  }

  /**
   * Sets a list of configuration variables that determine how we track your site.
   */
  private _configureGemius() {
    window.pp_gemius_use_cmp = 0;
    window.pp_gemius_dnt = 0;
    // lines below shouldn't be edited
    window.gemius_pending = function (i: string) {
      (window as any)[i] =
        (window as any)[i] ||
        function () {
          const x = ((window as any)[i + "_pdata"] = (window as any)[i + "_pdata"] || []);
          // eslint-disable-next-line prefer-rest-params
          x[x.length] = arguments;
        };
    };
    window.gemius_pending("gemius_hit");
    window.gemius_pending("gemius_event");
    window.gemius_pending("pp_gemius_hit");
    window.gemius_pending("pp_gemius_event");
  }

  private _trackPageChartbeat(asset: PageContent, path?: string) {
    // Chartbeat
    // La valeur du tag 'section' correspond au titre de la Catégorie Auvio du programme (ou du média auquel est rattaché le média de la page concernée).
    // En cas de page sans média ou programme, alors la valeur est 'home'
    // La valeur du tag 'author' correspond au titre du programme (ou du média auquel est rattaché le média de la page concernée).
    // En cas de page sans média ou programme, alors la valeur est 'home'
    if (path) this._sf_async_config.path = path;
    switch (asset.pageType) {
      case "MEDIA":
      case "LIVE":
      case "PROGRAM":
        this._sf_async_config.sections = asset.category?.label ?? "";
        this._sf_async_config.authors = asset.title;
        break;
      default:
        this._sf_async_config.sections = "home";
        this._sf_async_config.authors = "home";
        break;
    }

    Log.analytics.debug("TrackingHelper trackPage", this._sf_async_config);
  }

  private _trackPageGemius(data: RTBF.Gemius) {
    const gemiusTrackPool: string[][] = [];
    if (data.key) {
      const pp_gemius_extraparameters = ["lan=FR"];
      if (data.key) {
        pp_gemius_extraparameters.push(`key=${data.key}`);
      }
      if (data.subs) {
        pp_gemius_extraparameters.push(`subs=${data.subs}`);
      }
      if (data.free) {
        pp_gemius_extraparameters.push(`free=${data.free}`);
      }
      gemiusTrackPool.push(pp_gemius_extraparameters);
    }
    if (this.gemiusData?.id.page && gemiusTrackPool.length) {
      for (const data of gemiusTrackPool) {
        window.pp_gemius_hit(this.gemiusData?.id.page, ...data);
      }
    }
  }

  track(data: ITrackingData) {
    if (data.event_name === "page_view") {
      if (data.gemius) {
        this._trackPageGemius(data.gemius);
      }
      if (data.chartbeat) {
        this._trackPageChartbeat(data.chartbeat.asset, data.path);
      }
    }

    if (data.gtag.page_title) this.pageTitle = data.gtag.page_title;
    if (data.gtag.page_id) this.pageId = data.gtag.page_id;
    if (data.gtag.page_type) this.pageType = data.gtag.page_type;

    data.gtag = {
      ...data.gtag,
      platform: DS.platform.type,
      user_id: `${APIGigyaOIDC.userInfo$.value?.uid}_${this._UIDSuffix}`,
      version: _APP_VERSION_,
      page_title: this.pageTitle,
      page_id: this.pageId,
      page_type: this.pageType,
      page_referrer: this.pageReferrerPath,
    };

    if (Config().GTAG.debug) {
      const gtagWidget = RTBF.TrackingWidget.safeParse(data.gtag);
      const secondParam = (() => {
        switch (data.event_name) {
          case "page_view":
            return data.gtag.page_id;

          case "card_interaction":
          case "widget_interaction":
            if (gtagWidget.success)
              return `${gtagWidget.data.action}` + (gtagWidget.data.area ? ` - ${gtagWidget.data.area}` : "");
            return ``;

          default:
            return "";
        }
      })();
      console.group("GTAG", data.event_name, secondParam);
      console.table(data);
      console.groupEnd();
    }

    if (data.event_name === "user_properties") {
      window.gtag("set", data.event_name, data.gtag);
    } else {
      window.gtag("event", data.event_name, data.gtag);
    }
  }

  /**
   * Track a program with gemius
   * @param asset The player asset
   */
  trackProgram(asset: IPlayerAsset) {
    const dataGemius = asset.meta?.gemius?.player;
    if (dataGemius !== undefined) {
      const additionalParameters: { [key: string]: string | number | undefined } = {
        ...dataGemius,
        programName: dataGemius.sct,
        programDuration: asset.isLive() ? -1 : asset.resource.duration,
        programType: dataGemius.scte,
        channel: dataGemius.channel,
      };
      if (asset.isLive()) additionalParameters.programTransmission = "broadcast_live";

      if (asset.resource.id !== "") this._gemiusPlayer?.newProgram(asset.resource.id, additionalParameters);
    }
  }

  /**
   * Track an promo with gemius
   * @param asset The asset
   */
  trackPromo(asset: IPlayerAsset) {
    const dataGemius = asset.meta?.gemius?.player;
    const programId = asset.resource.id;
    const adId = dataGemius?.tv ?? "";
    if (!(programId && adId)) return;

    if (this._gemiusPlayer) {
      const additionalParameters: { [key: string]: string | number | undefined } = {
        adDuration: asset.resource.duration,
        adType: "promo",
        adName: dataGemius?.sct ?? "",
        volume: -1,
      };

      this._gemiusPlayer.newAd(adId, additionalParameters);
    }
  }

  /*
   * Track a program event
   * Check if the event is the same as last one to prevent Gemius strange behavior
   * Ex: Sending multiple play events make Gemius sending a suite of play, pause, play, pause...
   */
  trackProgramEvent(programId: string, event: GemiusPlayerEvent, offset: number) {
    if (event === this._lastProgramEvent) return;
    this._gemiusPlayer?.programEvent(programId, offset, event);
    this._lastProgramEvent = event;
  }

  /*
   * Track an ad event
   * Check if the event is the same as last one to prevent Gemius strange behavior
   * Ex: Sending multiple play events make Gemius sending a suite of play, pause, play, pause...
   */
  trackAdEvent(asset: IPlayerAsset, event: GemiusPlayerEvent, offset: number) {
    if (event === this._lastProgramEvent) return;
    const programId = asset.resource.id;
    const adId = asset.resource.assetId;
    if (programId && adId && this._gemiusPlayer) {
      const additionalParameters: { [key: string]: string | number | undefined } = {
        addPosition: offset,
      };

      this._gemiusPlayer.adEvent(programId, adId, 0, event, additionalParameters);
      this._lastProgramEvent = event;
    }
  }

  resetLastProgramEvent() {
    this._lastProgramEvent = undefined;
  }

  /*
   * Generate Mux plugin options from content specific data
   * @param {IMuxData} muxData
   * @param {{ name?: string; version: string }} playerInfo?
   * @returns {IMuxDataPluginOptions}
   */
  muxDataRedbee(muxData: Partial<IMuxData>, playerInfo?: { name?: string; version: string }): IMuxDataPluginOptions {
    const muxMeta = {
      ...{
        env_key: Config().REDBEE.muxKey,
        page_type: "watchpage",
      },
      ...muxData,
    };
    switch (DS.platform.type) {
      case PlatformType.tizen:
        muxMeta.player_name = "tizen_redbee";
        break;
      case PlatformType.webos:
        muxMeta.player_name = "lg_redbee";
        break;
      case PlatformType.ps4:
        muxMeta.player_name = `ps4_${playerInfo?.name ?? ""}_${playerInfo?.version ?? ""}`;
        break;
      case PlatformType.ps5:
        muxMeta.player_name = `ps5_${playerInfo?.name ?? ""}_${playerInfo?.version ?? ""}`;
        break;
      default:
        muxMeta.player_name = "browser_redbee";
    }
    if (playerInfo !== undefined) muxMeta.player_version = playerInfo.version;
    return {
      debug: env() === "UAT",
      muxDataMetadata: muxMeta,
      privacySettings: {
        respectDoNotTrack: false,
        disableCookies: false,
      },
    };
  }

  /*
   * Use muxDataRedbee function and transform the plugin options with proper keys for use with plugins directly supplied by Mux
   * @param {IMuxData} muxData
   * @param {{ name?: string; version: string }} playerInfo?
   * @returns {IMuxDataPluginOptions}
   */
  muxData(muxData: Partial<IMuxData>, playerInfo?: { name?: string; version: string }): IMuxDataPluginOptionsMux {
    const { muxDataMetadata, ...data } = this.muxDataRedbee(muxData, playerInfo);
    return {
      ...data,
      data: muxDataMetadata,
    };
  }

  async snowplowEvent<EventName extends keyof SnowplowEventsMapping>(
    eventName: EventName,
    eventParams?: SnowplowEventsMapping[EventName]
  ) {
    const commonEventParams: SnowplowBaseEventParams = {
      user_id: APIGigyaOIDC.userInfo$.value?.uid ?? "nc",
      timestamp: Date.now(),
    };

    const payload: SnowplowPayload = {
      ...commonEventParams,
      ...SnowplowHelper.getSnowplowSpecificEventTypeParams(eventName),
      ...SnowplowHelper.sanitizeEventParams(eventParams),
    };

    void SnowplowHelper.snowplowEvent(eventName, payload);
  }

  /* NOTE: Code kept if client change is mind after backend can't handle the events load
  trackPageChange() {
    navigationStack.pages$.didChange(
      (pages, previousPages) => {
        const openOrClosePopupPage = pages.at(-1)?.isPopup?.() === true || previousPages.at(-1)?.isPopup?.() === true;
        if (openOrClosePopupPage === true || pages.length === 0) return;
        this._pageLoadTimestamp = Date.now();
      },
      null,
      true
    );
  }
  */

  /**
   * Returns the path of the current page from the navigation stack.
   * If no valid page is found, returns an empty string.
   *
   * @returns {string} The current page path or an empty string.
   */
  public getCurrentPagePath(): string {
    const currentPage = navigationStack.topPage;

    if (currentPage !== undefined && currentPage instanceof GenericPage && currentPage.path.length) {
      return currentPage.path;
    }

    return "";
  }

  /**
   * Constructs and returns the full URL of the current page.
   * Combines the base application URL with the current page path.
   * If no valid path is found, returns an empty string.
   *
   * @returns {string} The full URL of the current page or an empty string.
   */
  public getCurrentPageUrl(): string {
    const currentPagePath = this.getCurrentPagePath();

    if (currentPagePath.length) return Config().SNOWPLOW.baseApplicationUrl + currentPagePath;

    return "";
  }

  /**
   * Constructs and returns the full URL of the referrer page.
   * Combines the base application URL with the referrer path.
   * If no valid referrer path is found, returns an empty string.
   *
   * @returns {string} The full URL of the referrer page or an empty string.
   */
  public getPageReferrerUrl(): string {
    return this.pageReferrerPath.length ? Config().SNOWPLOW.baseApplicationUrl + this.pageReferrerPath : "";
  }
}

export const TrackingHelper = TrackingManager.instance;
