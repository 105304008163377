import "./favoriteWidgetView.scss";

import { MediaTileView } from "~/components/tiles/media/mediaTileView";
import { ProgramTileView } from "~/components/tiles/program/programTileView";
import { mouseSupportArrowHoriParams } from "~/components/views/common/mouseSupport/mouseSupportArrow";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";
import { DS } from "~/libs";
import { GenericPage } from "~/pages/generic/genericPage";
import { PairingPageView } from "~/pages/pairing/pairingPage";

import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { FavoriteHelper } from "../../../tools/favoriteHelper";
import { FocusManager } from "../../../tools/focusHelper";
import { CtaCard, EmptyCard, FavoriteCardWithResource, GuestCard, Widget } from "../../../utils/rtbf/models";
import { FocusTracker } from "../../focusTracker";
import { MoreContentTile } from "../../tiles/moreContent/moreContent";
import { FavorisSpecificView } from "../../views/FavorisSpecificView/FavorisSpecificView";
import { WidgetView } from "../widgetView";

/**
 *  Favorite widget, built by the GenericPage.
 * @param widget The  widget.
 */
type FavoriteWidgetModelSource = FavoriteCardWithResource | CtaCard | GuestCard | EmptyCard;

export class FavoriteWidgetView extends WidgetView {
  private _focusTracker?: FocusTracker;
  private _focusManager?: FocusManager;
  protected _list: DS.IListComponent<
    FavoriteWidgetModelSource,
    MediaTileView | ProgramTileView | MoreContentTile | FavorisSpecificView
  >;
  private _widget;
  private _guestState;
  private _emptyState;
  private _moreContentStyle: "program" | undefined;
  private _modelSource$: DS.ModelSource<FavoriteWidgetModelSource>;

  constructor(widget: Widget) {
    super(widget.id, "favoriteWidget");
    this._setAnalytics(widget);
    this._widget = widget;
    this._guestState = this._widget.states?.guest;
    this._emptyState = this._widget.states?.empty;

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "widgetTitle",
      innerText: widget.title,
    });

    this._addSubtitleIfAvailable(widget);

    this.delegate = this._list = DS.createListComponent(
      {
        id: `${widget.id}/list`,
        className: "widgetList",
        modelSource$: (this._modelSource$ = new DS.ModelSource(this._content(widget.contentPath ?? ""))),
        viewFactory: card => {
          switch (card.resourceType) {
            case "FAVORITE":
              switch (card.resource.resourceType) {
                case "MEDIA":
                case "MEDIA_PREMIUM":
                  return new MediaTileView(card.resource, "listSize");
                case "PROGRAM":
                  return new ProgramTileView(card.resource, "listSize");
              }
              break;
            case "CTA":
              return new MoreContentTile(card, this._moreContentStyle);
            case "GUEST":
            case "EMPTY":
              return new FavorisSpecificView(card);
          }
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && mouseSupportArrowHoriParams(),
        onSelect: (card, index) => {
          super._trackCardAnalytics({
            gtag: {
              card_order: index,
            },
          });

          // Send a snowplow event only if the content is not empty or if the user is not logged in
          if (!super.isWidgetEmpty(this._list)) {
            super._trackCardAnalytics({
              card: { ...card, order: index },
            });
          }

          switch (card.resourceType) {
            case "FAVORITE":
              navigationStack.pushPage(new GenericPage(card.resource.path));
              break;
            case "CTA":
              navigationStack.pushPage(new GenericPage(card.path, "mosaicFavorites"));
              break;
            case "GUEST":
            case "EMPTY":
              if (card.cta?.action === "LOGIN") navigationStack.pushPage(new PairingPageView());
              if (card.cta?.action === "NAVIGATE") navigationStack.pushPage(new GenericPage(card.cta.path));
          }
          return true;
        },
      },
      list => {
        this._focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this._focusManager = new FocusManager(this._focusTracker);
        this.collectRelease(this._focusTracker.onRelease);

        list.focusedIndex$.didChange(
          index => {
            if (index === undefined) return;
            const card = list.modelFromIndex(index);

            if (card === undefined) return;
            this._focusManager?.manageFocus(card);
          },
          this,
          true
        );
        list.visibleRange$.didChange(
          (range, previousRange) => {
            // Send a snowplow event only if the content is not empty or if the user is not logged in
            if (super.isWidgetEmpty(this._list)) return;

            this._snowplowTracking(range, this._list, previousRange);
          },
          this,
          true
        );
      }
    );
  }

  async onShown() {
    // Refresh widget content when user has added or deleted a favorite

    // If a favorite has been added or removed
    if (this._hasFavoritesChanged()) {
      await this._refreshWidgetContent();
    }
  }

  /**
   * Checks whether the number of favorites in the model source differs
   * from the current list of favorite IDs, based on the widget type.
   *
   * @private
   * @returns `true` if a favorite has been added or removed; otherwise, `false`.
   */
  private _hasFavoritesChanged(): boolean {
    // Determine whether it's the Favorite Programs or Favorite Media widget
    const currentFavoritesIds =
      this._widget.type === "FAVORITE_PROGRAM_LIST" ? FavoriteHelper.programIds : FavoriteHelper.mediaIds;

    return (
      this._modelSource$.value.filter(item => item.resourceType === "FAVORITE").length !== currentFavoritesIds.length
    );
  }

  /**
   * Refreshes the widget content by updating the model source.
   *
   * @private
   * Updates the current model source by fetching new content from the widget's
   * content path.
   *
   * @async
   */
  private async _refreshWidgetContent() {
    this._modelSource$.value = await this._content(this._widget.contentPath ?? "");
  }

  /**
   * This function return the modelSource according to those rules :
   * - Return the guest state if not connected
   * - Fetch items and return them, with a CTA if available
   *   if the list is empty display the empty state
   * - If previous conditions couldn't be met, return an empty array
   * @param contentPath The widget content path
   * @returns A promise with widget.content
   */
  private async _content(contentPath: string): Promise<FavoriteWidgetModelSource[]> {
    if (!APIGigyaOIDC.userInfo$.value && this._guestState) return [this._guestState];

    try {
      const cards = await APIAuvio.widgetFavorites(contentPath);

      if (cards.length === 0 && this._emptyState) return [this._emptyState];
      if (cards.length > 0 && cards[0].resource.resourceType === "PROGRAM") this._moreContentStyle = "program";
      if (this._widget.cta) return [...cards, this._widget.cta];

      return cards;
    } catch {
      return this._emptyState ? [this._emptyState] : [];
    }
  }
}
